.adminLogo{
    cursor: pointer;
    padding:10px;
    text-align: center;
    img{
        width:160px;
    }
    p{
        color:white;
    }
}