@import '~antd/dist/antd.css';
html,
body {
  height: 100%;
  min-width: 1220px;
}

#root {
  min-height: 100%;
  min-width: 1280px;
  display: flex;

  &>div {
    min-height: 100%;
    flex: 1;
  }
}

.center_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.between_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-row{
    flex:1;
    margin-right: 20px;
  }
}

.flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.position_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.W1220 {
  // max-width: 1220px;
  // min-width: 800px;
  // margin: 0 auto;
  // padding: 0 9%;
  // min-height: 100%;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
}

.W1220_ {
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  transition: color .4s ease;
  color: #005DFF;

  &:hover {
    color: #005DFF !important
  }
}
.link30{
  color:#303030;
  width: 102px;
  display: inline-block;
}
.delete {
  color: #005DFF;
  cursor: pointer;
}

.table_control {
  span {
    padding-right: 10px;
  }
}

.W80 {
  width: 80px;
}

.left10 {
  margin-left: 10px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.theme_color {
  color: #005DFF !important;
  font-weight: bold;
}

.ant-radio-inner::after {
  background: #005DFF !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #005DFF !important;
}

.theme_bg {
  background: #005DFF;
}

.title_common {



  .titles {
    font-size: 22px;
    font-weight: bold;;
    color:#005DFF;
    position: relative;
    padding-left: 5px;

    &::after {
      content: '';
      position: absolute;
      left: -2px;
      top: 3px;
      bottom: 3px;
      width: 3px;
      border-radius: 3px;
      background: #005DFF;
    }
  }

}

/* 

修改分页默认样式
*/
@22PX: 28px !important;
@24PX: 24px !important;

.ant-pagination-item {
  // width: @22PX;
  height: @22PX;
  line-height: @22PX;
  min-width: @22PX;
}

.ant-pagination-item a {
  padding: 0;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  width: @22PX;
  height: @22PX;
  line-height: @22PX;
  min-width: @22PX;
}

.ant-pagination-options {
  .ant-pagination-options-quick-jumper {
    height: @22PX;
    line-height: @22PX;
  }

  height: @22PX;
  line-height: @22PX;

  input {
    height: @22PX;
    width: 30px !important;
  }
}

.ant-pagination.ant-table-pagination {
  display: flex;
  justify-content: center;
  float: initial !important;
}

/* 到这里 */

.ant-table-column-title {
  font-size: 14px;
  font-weight: bold;
  color: rgba(48, 48, 48, .5);
}

.ant-pagination-item-active,
.ant-pagination-item:hover {
  a {
    color: #005DFF !important;
  }
}


.ant-btn{
  border-color: rgb(217, 217, 217)!important; 
  height: 30px!important;
  line-height: 24px!important;
  font-size: 12px!important;
  color: rgba(0, 0, 0, 0.65)!important;
}
.ant-btn:hover{
  color: rgba(0, 0, 0, 0.65)!important;
}
.success_btn {
  border: 1px solid rgba(170, 0, 5, .5) !important;
  height: 30px!important;
  line-height: 24px!important;
  background: #005DFF!important;
  color: #ffffff!important;
}
.ant-btn-primary{
  border: 1px solid rgba(170, 0, 5, .5) !important;
  height: 30px!important;
  line-height: 30px!important;
  background: #005DFF!important;
  color: #ffffff!important;
}
.success_btn:hover{
  color: #ffffff!important;
}
.ant-btn-primary:hover{
  color: #ffffff!important;
}
.error_btn {
  border-color: rgb(217, 217, 217)!important;
  // color: #44A1FC !important;
  background: #fff !important;
  height: 30px!important;
  line-height: 24px!important;
}
.cancel_btn {
  border-color: rgb(217, 217, 217)!important;
  // color: #44A1FC !important;
  background: #fff !important;
  height: 30px!important;
  line-height: 24px!important;
}

.ant-drawer-body {
  line-height: 1;
  // padding: 0 24px !important;
}

.right_group_container {
  flex: 1;
  position: relative;
  padding-bottom: 60px;

  button {
    height: 28px;
  }

  .cancel_btn {
    margin-right: 20px;
  }

  .right_group_title {
    margin: 30px 0;
  }

  .right_group_content_box {
    padding-bottom: 70px;
  }
}

.right_group_title_ {
  font-size: 16px;
  font-weight: bold;;
  color: #005DFF;
}

.btn_box_bottom {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 20px 10px;
  background: #ffffff;

  .btn_right {
    display: flex;
    padding: 10px;
  }

  button {
    height: 30px;
    line-height: 30px;
  }

  .cancel_btn {
    margin-right: 20px;
  }
}

.forms_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .left{

  }
  .right{
    text-align: right;
    padding-right: 0px;
  }
  input.ant-input {
    height: 30px;
    margin-bottom: 10px;
    width: 100%;
  }
 
  .ant-select-selection {
    max-width: 140px;
    min-width: 80px;
    height: 30px;
    margin-bottom: 10px;
    width: 240px;
  }

  button {
    height: 30px;
    font-size: 12px;
    // font-weight: bold;
  }
  
  .search_btn {
    background: #005DFF!important;
    color: rgba(255, 255, 255, 1)!important;
    border-color: #005DFF!important;
    line-height: 24px!important;
  }
  .search_btn:hover{
    color: rgba(255, 255, 255, 1)!important;
  }

  .ant-calendar-picker-icon {
    display: none !important;
  }
  .ant-form-item{
    button{
      position: relative;
      top:-3px;
    }
  }
}

.ant-form-item-control {
  position: relative;
  line-height: 40px;
  zoom: 1;
  .ant-form-item-children {
    position: static;
  }

  .other_btn {
    position: absolute;
    right: 0;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ant-drawer-content {
  .ant-form-item {
    margin-bottom: 0;
  }
}

input {
  outline: none;
  border: 0;
  border: 1px solid rgba(0, 0, 0, .2);
}

.table_cover_img {
  height: 50px;
  cursor: pointer;
}

.pre_upload_cover_image {
  width: 100%;
  max-height: 500px;
  max-width: 500px;
}

.upload_cover_image {
  max-height: 40px;
  cursor: pointer;
  margin-left: 20px;
}

.ant-drawer-content-wrapper {
  min-width: 360px !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.forms_box{
  margin: 12px 0;
  .forms_ input.ant-input{
    margin-bottom: 0;
    margin-top: 0;
  }
}


td {
  max-width: 320px;
}

.group_container {
  display: flex;
  flex: 1;

  .right_content_group {
    flex: 1;
    padding: 0 24px;

    .sub_title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(48, 48, 48, 1);
      margin-top: 30px;
    }

    .btn_box {
      margin-top: 20px;

      button {
        margin-right: 12px;
        margin-bottom: 10px;
      }
    }
  }

}
#dues_date .ant-calendar-picker{
  width: auto;
}
.line_form_item {

  input,
  .ant-calendar-picker {
    width: 100%;
  }
  .rangeTimePicker{
    input{
      width: auto;
    }
  }
  

  .ant-form-item {
    flex: 1;
    margin-right: 20px;
  }

  .img_item {
    flex: 0;
  }
}

.line {
  height: 1px;
  background: #ebebeb;
  margin: 10px 0;
}
.ztree .line{
  height: auto;
}
.h1 {
  font-size: 28px;
  font-weight: bold;;
  margin: 10px 0;
  color: #000000;
}

.h1 {
  font-size: 18px;
  font-weight: bold;;
  margin: 10px 0;
  color: #000000;
}

.people_box {
  border: 1px solid rgba(217, 217, 217);
  border-radius: 4px;
  min-height: 32px;
  line-height: 32px;
  overflow-x: auto;
  padding-right: 40px;
  padding-left: 10px;
  background-color: #f5f5f5;
}
.people_box.disabled {
  border: 1px solid rgba(217, 217, 217);
  cursor: not-allowed;
}

.ant-table-tbody>tr>td {
  font-size: 13px;
  max-width: 300px;
}
.ant-dropdown-menu-item-active{
  background: rgba(170, 0, 5, 0.2)!important;
    color: rgba(170, 0, 5, 0.5)!important;
}
.headMenu.ant-dropdown-menu {
  width: 150px;
  border-radius: 0;
  margin-top: 21px;
  padding:0;
  border-radius:0 0 4px 4px ;
  .ant-dropdown-menu-item{
    padding:0 30px;
    height: 50px;
    line-height: 50px;
  }
  .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a{
    padding:0 12px;
    font-weight: bold;
  }
  .ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider{
    margin:0 0 4px;
    font-weight: bold;
  }
}

#root>.ant-layout{
  min-width: 1280px;
  overflow-y: auto;
  position: relative;
}

.sider_menu_container .anticon{
  font-size: 16px;
}

.outstanding_member_page{
  // padding-right: 10px;
  .forms_box{
      .right{
          .ant-form-item:last-child{
              margin-right: 0;
          }
      }
  }
  .table_box{
    padding-right: 0!important;
  }
} 

#globalLoading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(250, 250, 250, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}
.ant-steps-item-process .ant-steps-item-icon{
  background: #ff4248!important;
  border-color: #ff4248!important;
}
.ant-steps-item-finish .ant-steps-item-icon{
  border-color: #ff4248!important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
  color: #ff4248!important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background: #ff4248!important;
}
.iconCirWrap {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.layout_content_lefttree{
  width: 100%;
  margin-top: 2px;
  overflow: hidden;
  .content_{
      height: 100%;
  }
}
.hide{
  display: none!important;
}

.chooseInput{
  background-color: #fff!important;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65)!important;
  line-height: 14px;
  position: relative;
  top: 3px;
}
.chooseInput::-webkit-input-placeholder { /* WebKit browsers */
  color: rgba(0, 0, 0, 0.65)!important;
  font-size: 14px;
}
.ant-tabs-nav .ant-tabs-tab-active{
  color: #005DFF!important;
}
.ant-tabs-ink-bar{
  background-color: #005DFF!important;
}
.ant-tabs-nav .ant-tabs-tab:hover{
  color: #005DFF!important;
}
// .ant-table-tbody > tr > td{
//   line-height: 16px;
// }



*{
  margin:0;
  padding:0;
}
.wrap{
  width: 100%;
}
.Htmlcontent{
  width: 1160px;
  margin:0 auto;
  overflow: hidden;
}
.more{
  background: #F0F0F0;
  border: 2px solid #9E9EA6;
  border-radius: 20px;
  width: 175px;
  height: 44px;
  font-size: 18px;
  color: #9E9EA6;
  line-height: 44px;
  margin:35px auto;
  text-align: center;
  cursor: pointer;
}
.btn{
  width: 174px;
  height: 34px;
  background: linear-gradient(90deg, #2878FF 0%, #005DFF 100%);
  border-radius: 5px;

  font-size: 16px;
  color: #FFFFFF;
  line-height: 34px;
  text-align: center;
}
.title{
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 18px; 
  margin:45px auto 35px;
  text-align: center;
}
.title1{
  height: 30px;
  font-size: 32px;
  color: #787878;
  margin:24px 0 35px;
}

.headerTop{
  width: 100%;
  height: 540px;
  // background: url('./images/V2/bannerBG.png') no-repeat 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.headerBanner{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #ccc;
  bottom: 0;
  .ant-carousel{
    height: 100%;
    .slick-slider{
      height: 100%;
      .slick-list{
        height: 100%;
      }
      .slick-track{
        height: 100%;
      }
    }
    .slick-slide {
      text-align: center;
      height: 100%;
      line-height: 160px;
      background: #364d79;
      overflow: hidden;
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.headerTopBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 10;
}
.headerLogo{
  font-size: 17px;
  color: #FFFFFF;
  width:200px;
  text-align: center;
}

.headerTopBar .headerLogo img{
  width: 175px;
  height: 27px;
}
.headerNav{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 600px;

}
.headerNavItem{
  font-size: 18px;
  color: #FFFFFF;
  height: 18px;
  cursor: pointer;
  position: relative;
}
.headerNavItem a{
  color: #FFFFFF;
  text-decoration: none;
}
.headerNavItem.checked::after{
  content: '';
  display: inline-block;
  bottom: 10px;
  left:0;
  width: 100%;
  border-bottom: 2px solid #fff;
}
.headerLoginWrap{
  width: 140px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #FFFFFF;
  cursor: pointer;
}
.loginLine{
  width: 1px;
  background-color: #fff;
  height: 100%;
}
.bannerwwrap{
  position: absolute;
  left:0;
  top:0;
  height: 100%;
  width: 100%;
  img{
    width: 100%;
  }
}
.headerDes{
  position: absolute;
  left: 50%;
  top:50%;
  width: 550px;
  height: 140px;
  margin-left: -250px;
  margin-top: -70px;
  flex: 1;
  text-align: center;
  font-size: 52px;
  font-family: PingFang SC;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 10;
}

.breadcrumbWrap{
  height: 51px;
}
.breadcrumbContent{
  height: 51px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #5F5F5F;
  border-bottom: 1px solid #A7A7A7;
  cursor: pointer;
}





.footer{
  height: 286px;
  background-color: #000;
}
.footer .btn{
  margin-top: 28px;
}
.footerContent{
  padding:40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
.footerRight{
 width: 650px;
 display: flex;
 flex-direction: row;
 justify-content: space-between; 
}
.footerRightItem{

}
.footerRightTitle{
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.8;
  margin-bottom: 29px;
}
.footerRightList a{
  display: inline-block;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.6;
  margin-bottom: 14px;
}
.Copyright{

  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
}
.Copyright a{
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-decoration: none;
}

.caseWrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 35px;
  flex-wrap: wrap;
}
.case{
  height: 424px;
  width: 360px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.case img{
  width: 359px;
  height: 204px;
  border-radius: 10px 10px 0 0;
}
.case .caseDesc{
  margin:19px;
}
.case .caseDescTitle{
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}
.case .caseDescContent{
  margin-top: 17px;
  font-size: 16px;
  font-family: PingFang SC;
  color: #505050;
  line-height: 24px;
}
.case .caseMore{

  font-size: 16px;
  color: #1B6BBD;
  line-height: 18px;
  margin:19px;
  text-align: right;
}



.rz{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #A7A7A7;
}
.rz img{
  width: 100%;
  height: 250px;
}
.renz{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding:42px 0 44px;
}
.renzItem{
  height: 100px;
  text-align: center;
  font-size: 14px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.renzItem img{
  height: 80px;
  // width: auto;
}