* {
    margin: 0;
    padding: 0;
}
p{
    margin:0;
}
.wrap {
    width: 100%;
}

.Htmlcontent {
    width: 1160px;
    margin: 0 auto;
    overflow: hidden;
}

.more {
    background: #F0F0F0;
    border: 2px solid #9E9EA6;
    border-radius: 20px;
    width: 175px;
    height: 44px;
    font-size: 18px;
    color: #9E9EA6;
    line-height: 44px;
    margin: 35px auto;
    text-align: center;
    cursor: pointer;
}

.btn {
    width: 174px;
    height: 34px;
    background: linear-gradient(90deg, #2878FF 0%, #005DFF 100%);
    border-radius: 5px;

    font-size: 16px;
    color: #FFFFFF;
    line-height: 34px;
    text-align: center;
}

.title {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    line-height: 18px;
    margin: 45px auto 35px;
    text-align: center;
}

.title1 {
    height: 30px;
    font-size: 32px;
    color: #787878;
    margin: 24px 0 35px;
}

.headerTop {
    width: 100%;
    height: 540px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.headerBanner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: #ccc;
    bottom: 0;
    .ant-carousel  {
        height: 100%;

        .slick-slider {
            height: 100%;

            .slick-list {
                height: 100%;
            }

            .slick-track {
                height: 100%;
            }
        }

        .slick-slide {
            text-align: center;
            height: 100%;
            line-height: 160px;
            background: #364d79;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.headerTopBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    z-index: 10;
}

.headerLogo {
    font-size: 17px;
    color: #FFFFFF;
    width: 200px;
    text-align: center;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerTopBar .headerLogo img {
    width: 175px;
    height: 27px;
}

.headerNav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 600px;
}

.headerNavItem {
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    position: relative;
    line-height: 40px;
    height: 40px;
}

.headerNavItem a {
    color: #FFFFFF;
    text-decoration: none;
}

.headerNavItem.checked::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #fff;
}

.headerLoginWrap {
    width: 140px;
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

.loginLine {
    width: 1px;
    background-color: #fff;
    height: 100%;
}

.bannerwwrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    img {
        width: 100%;
    }
}

.headerDes {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 550px;
    height: 140px;
    margin-left: -250px;
    margin-top: -70px;
    flex: 1;
    text-align: center;
    font-size: 52px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 63px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 10;
}

.breadcrumbWrap {
    height: 51px;
}

.breadcrumbContent {
    height: 51px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: #5F5F5F;
    border-bottom: 1px solid #A7A7A7;
    cursor: pointer;
}





.footer {
    height: 326px;
    background-color: #000;
    .footerLeft{
        width: 210px;
        .headerLogo{
            img{
                width: 210px;
            }
        }
    }
}

.footer .btn {
    margin-top: 28px;
    width: 100%;
}

.footerContent {
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.footerRight {
    width: 650px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerRightItem {}

.footerRightTitle {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 29px;
}

.footerRightList a {
    display: inline-block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
    margin-bottom: 14px;
}

.Copyright {

    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
}

.Copyright a {
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    text-decoration: none;
}

.caseWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 35px;
    flex-wrap: wrap;
}

.case {
    height: 424px;
    width: 360px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.case img {
    width: 359px;
    height: 204px;
    border-radius: 10px 10px 0 0;
}

.case .caseDesc {
    margin: 19px;
}

.case .caseDescTitle {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}

.case .caseDescContent {
    margin-top: 17px;
    font-size: 16px;
    font-family: PingFang SC;
    color: #505050;
    line-height: 24px;
}

.case .caseMore {

    font-size: 16px;
    color: #1B6BBD;
    line-height: 18px;
    margin: 19px;
    text-align: right;
}



.rz {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #A7A7A7;
}

.rz img {
    width: 100%;
    height: 250px;
}

.renz {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 42px 0 44px;
}

.renzItem {
    height: 100px;
    text-align: center;
    font-size: 14px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.renzItem img {
    height: 80px;
}